interface DataLayerData {
    [key: string]: any;
  }
  
  interface DataLayerWrapper {
    push(data: DataLayerData): void;
    get(key: string): any;
    set(key: string, value: any): void;
    remove(key: string): void;
  }
  
  export const DataLayer: DataLayerWrapper = {
    push: function(data: DataLayerData): void {
      if (typeof data !== 'object') {
        data = {};
      }
      // @ts-ignore
      window.dataLayer.push(data);
    },
    get: function(key: string): any {
      let value: any;
      // @ts-ignore
      if (window.dataLayer && window.dataLayer.length) {
        // @ts-ignore
        for (let i = window.dataLayer.length - 1; i >= 0; i--) {
            // @ts-ignore
          if (window.dataLayer[i].hasOwnProperty(key)) {
            // @ts-ignore
            value = window.dataLayer[i][key];
            break;
          }
        }
      }
      return value;
    },
    set: function(key: string, value: any): void {
      const data = {};
    // @ts-ignore  
      data[key] = value;
      this.push(data);
    },
    remove: function(key: string): void {
      const data: DataLayerData = {};
      data[key] = undefined;
      this.push(data);
    }
  };