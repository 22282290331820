export type Place = {
  lat: number;
  lng: number;
  name: string;
  emoji?: string;
};


export const citiesToAlbumIDs = {
  "havana": "72177720308096528",
  "tulum": "72177720308078706",
  "reykjavik": "72177720308078681",
  "santiago": "72177720308092364",
  "milan": "72177720308081412",
  "panamacity": "72177720307872856",
  "machupicchu": "72177720307872611",
  "melbourne": "72177720307885734",
  "sydney": "72177720307885694",
  "montreal": "72177720307746403",
  "bali": "72177720307746028",
  "mykonos": "72177720307745978",
  "sayulita": "72177720307730185",
  "paris": "72177720307727027",
  "berlin": "72177720307722696",
  "galway": "72177720307741683",
  "prague": "72177720307725660",
  "warsaw": "72177720307741608",
  "hongkong": "72177720307740143",
  "seoul": "72177720307721041",
  "istanbul": "72177720307737059",
  "sofia": "72177720307721915",
  "amsterdam": "72177720307737958",
  "copenhagen": "72177720307737703",
  "stockholm": "72177720307718596",
  "oslo": "72177720307722802",
  "lisbon": "72177720307717486",
  "cairo": "72177720307721872",
  "jamaica": "72177720307694897",
  "ecuador": "72177720307693160",
  "buenosaires": "72177720307694222",
  "medellin": "72177720307707223",
  "guatemala": "72177720307707173",
  "saopaulo": "72177720307691657",
  "tbilisi": "72177720307398858",
  "jordan": "72177720307381047",
  "riyadh": "72177720307284744",
  "mexicocity": "72177720307285128",
  "tokyo": "72177720307267146",
  "chicago": "72177720307268992",
  "london": "72177720307267895",
  "beirut": "72177720307282439",
  "newyork": "72177720309024120",
  "sanfrancisco": "72177720309024157",
  "atlanta": "72177720309023220",
  "seattle": "72177720309037988",
  "austin": "72177720309023137",
  "nashville": "72177720309034829",
  "guadalajara": "72177720308983762",
  "ftlauderdale": "72177720308284438",
  "charleston": "72177720308269775",
  "denver": "72177720309048280",
  "portland": "72177720309049232",
  "santafe": "72177720309046191",
  "lasvegas": "72177720309048125",
  "oklahomacity": "72177720309059529"
};

const places: Place[]  = [
  {
    lat: 40.7128,
    lng: -74.0060,
    name: "New York",
    emoji: "🗽"
  },
  {
    lat: 33.7490,
    lng: -84.3880,
    name: "Atlanta",
    emoji: "🏢"
  },
  {
    lat: 47.6062,
    lng: -122.3321,
    name: "Seattle",
    emoji: "🌧️"
  },
  {
    lat: 30.2672,
    lng: -97.7431,
    name: "Austin",
    emoji: "🎸"
  },
  {
    lat: 36.1627,
    lng: -86.7816,
    name: "Nashville",
    emoji: "🎶"
  },
  {
    lat: 20.6597,
    lng: -103.3496,
    name: "Guadalajara",
    emoji: "🌮"
  },
  {
    lat: 26.1224,
    lng: -80.1373,
    name: "Fort Lauderdale",
    emoji: "🏖️"
  },
  {
    lat: 32.7765,
    lng: -79.9311,
    name: "Charleston",
    emoji: "🌴"
  },
  {
    "name": "Havana",
    "emoji": "🇨🇺",
    "lat": 23.1136,
    "lng": -82.3666
  },
  {
    "name": "Tulum",
    "emoji": "🇲🇽",
    "lat": 20.2119,
    "lng": -87.4654
  },
  {
    "name": "Reykjavik",
    "emoji": "🇮🇸",
    "lat": 64.1466,
    "lng": -21.9426
  },
  {
    "name": "Santiago",
    "emoji": "🇨🇱",
    "lat": -33.4489,
    "lng": -70.6693
  },
  {
    "name": "Milan",
    "emoji": "🇮🇹",
    "lat": 45.4642,
    "lng": 9.1900
  },
  {
    "name": "Panama City",
    "emoji": "🇵🇦",
    "lat": 8.9824,
    "lng": -79.5199
  },
  {
    lat: 51.5074,
    lng: -0.1278,
    name: "London",
    emoji: "🇬🇧"
  },
  {
    lat: 35.6895,
    lng: 139.6917,
    name: "Tokyo",
    emoji: "🇯🇵"
  },
  {
    lat: 41.8781,
    lng: -87.6298,
    name: "Chicago",
    emoji: "🏙️"
  },
  {
    lat: 37.7749,
    lng: -122.4194,
    name: "San Francisco",
    emoji: "🌉"
  },
  // {
  //   lat: 34.0522,
  //   lng: -118.2437,
  //   name: "Los Angeles",
  //   emoji: "🌴"
  // },
  {
    lat: 24.7136,
    lng: 46.6753,
    name: "Riyadh",
    emoji: "🕌"
  },
  {
    lat: 19.4326,
    lng: -99.1332,
    name: "Mexico City",
    emoji: "🇲🇽"
  },
  {
    lat: 45.5017,
    lng: -73.5673,
    name: "Montreal",
    emoji: "🇨🇦"
    },
    {
      lat: 37.4467,
      lng: 25.3289,
      name: "Mykonos",
      emoji: "🇬🇷"
    },{
      lat: -13.1631,
      lng: -72.5450,
      name: "Machu Picchu",
      emoji: "🇵🇪"
    },
    {
      lat: -33.8651,
      lng: 151.2093,
      name: "Sydney",
      emoji: "🇦🇺"
    },{
      lat: -37.8136,
      lng: 144.9631,
      name: "Melbourne",
      emoji: "🇦🇺"
    },
  {
    lat: 33.8886,
    lng: 35.4955,
    name: "Beirut",
    emoji: "🇱🇧"
  },
  {
    lat: 14.5586,
    lng: -90.7291,
    name: "Guatemala",
    emoji: "🌋"
  },
  {
    lat: 6.2530,
    lng: -75.5646,
    name: "Medellin",
    emoji: "🏙️"
  },
  {
    lat: -1.8312,
    lng: -77.3710,
    name: "Ecuador",
    emoji: "🌳"
  },
  {
    lat: -34.6037,
    lng: -58.3816,
    name: "Buenos Aires",
    emoji: "🇦🇷"
  },
  {
    lat: 31.9566,
    lng: 35.9457,
    name: "Jordan",
    emoji: "🇯🇴"
  },
  {
    lat: -23.5505,
    lng: -46.6333,
    name: "Sao Paulo",
    emoji: "🇧🇷"
  },
  {
    lat: 23.1136,
    lng: -82.3666,
    name: "Havana",
    emoji: "🇨🇺"
  },
  {
    lat: 18.4761,
    lng: -77.8939,
    name: "Jamaica",
    emoji: "🇯🇲"
  },
  {
    lat: 30.0444,
    lng: 31.2357,
    name: "Cairo",
    emoji: "🇪🇬"
  },
  // {
  //   lat: -1.2921,
  //   lng: 36.8219,
  //   name: "Nairobi"
  // },
  {
    lat: 38.7223,
    lng: -9.1393,
    name: "Lisbon",
    emoji: "🇵🇹"
  },
  {
    lat: 48.8566,
    lng: 2.3522,
    name: "Paris",
    emoji: "🇫🇷"
  },
  {
    lat: 52.5200,
    lng: 13.4050,
    name: "Berlin",
    emoji: "🇩🇪"
  },
  // {
  //   lat: 45.4654,
  //   lng: 9.1859,
  //   name: "Milan",
  //   emoji: "🇮🇹"
  // },
  {
    lat: 52.2297,
    lng: 21.0122,
    name: "Warsaw",
    emoji: "🇵🇱"
    },
  {
    lat: 53.2707,
    lng: -9.0568,
    name: "Galway",
    emoji: "🇮🇪"
  },
  {
    lat: 50.0755,
    lng: 14.4378,
    name: "Prague",
    emoji: "🇨🇿"
  },
  {
    lat: 59.9139,
    lng: 10.7522,
    name: "Oslo",
    emoji: "🇳🇴"
  },
  {
    lat: 59.3293,
    lng: 18.0686,
    name: "Stockholm",
    emoji: "🇸🇪"
  },
  {
    lat: 55.6761,
    lng: 12.5683,
    name: "Copenhagen",
    emoji: "🇩🇰"
  },
  {
    lat: 52.3702,
    lng: 4.8952,
    name: "Amsterdam",
    emoji: "🇳🇱"
  },
  {
    lat: 42.6977,
    lng: 23.3219,
    name: "Sofia",
    emoji: "🇧🇬"
  },
  {
    lat: 41.0082,
    lng: 28.9784,
    name: "Istanbul",
    emoji: "🇹🇷"
  },
  {
    lat: 41.7151,
    lng: 44.8271,
    name: "Tbilisi",
    emoji: "🇬🇪"
  },
  {
    lat: 37.5665,
    lng: 126.9780,
    name: "Seoul",
    emoji: "🇰🇷"
  },
  {
    lat: 22.3193,
    lng: 114.1694,
    name: "Hong Kong",
    emoji: "🇭🇰"
  },
  // {
  //   lat: 47.3769,
  //   lng: 8.5417,
  //   name: "Zurich"
  // },
  {
    lat: -8.5069,
    lng: 115.2629,
    name: "Bali",
    emoji: "🏝️"
  },
  {
    lat: 39.7392,
    lng: -104.9903,
    name: "Denver",
    emoji: "⛰️"
  },
  {
    lat: 45.5122,
    lng: -122.6587,
    name: "Portland",
    emoji: "🌲"
  },
  {
    lat: 35.6869,
    lng: -105.9378,
    name: "Santa Fe",
    emoji: "🏜️"
  },
  {
    lat: 36.1699,
    lng: -115.1398,
    name: "Las Vegas",
    emoji: "🎰"
  },
  {
    lat: 35.4676,
    lng: -97.5164,
    name: "Oklahoma City",
    emoji: "⚡"
  },
  // {
  //   lat: -37.8136,
  //   lng: 144.9631,
  //   name: "Melbourne"
  // },
  // {
  //   lat: 8.9824,
  //   lng: -79.5199,
  //   name: "Panama City"
  // },
  // {
  //   lat: 9.9281,
  //   lng: -84.0907,
  //   name: "San Jose"
  // },
  {
    lat: 20.8689,
    lng: -105.4408,
    name: "Sayulita",
    emoji: "🏝️"
  },
  // {
  //   lat: 20.2119,
  //   lng: -87.4658,
  //   name: "Tulum"
  // },
  // {
  //   lat: 30.2672,
  //   lng: -97.7431,
  //   name: "Austin"
  // },
  // {
  //   lat: 25.7617,
  //   lng: -80.1918,
  //   name: "Miami"
  // },
  // {
  //   lat: 26.1224,
  //   lng: -80.1373,
  //   name: "Ft. Lauderdale"
  // },
  // {
  //   lat: 33.7490,
  //   lng: -84.3880,
  //   name: "Atlanta"
  // },
  // {
  //   lat: 38.2527,
  //   lng: -85.7585,
  //   name: "Louisville"
  // },
  // {
  //   lat: 35.6822,
  //   lng: -105.9397,
  //   name: "Santa Fe"
  // },
  // {
  //   lat: 36.1699,
  //   lng: -115.1398,
  //   name: "Las Vegas"
  // },
  // {
  //   lat: 29.9511,
  //   lng: -90.0715,
  //   name: "New Orleans"
  // },
  // {
  //   lat: 39.6296,
  //   lng: -79.9559,
  //   name: "Morgantown"
  // },
  // {
  //   lat: 36.1627,
  //   lng: -86.7816,
  //   name: "Nashville"
  // },
  // {
  //   lat: 42.3314,
  //   lng: -83.0458,
  //   name: "Detroit"
  // },
  // {
  //   lat: 39.9784,
  //   lng: -86.118,
  //   name: "Carmel"
  // },
  // {
  //   lat: 35.2271,
  //   lng: -80.8431,
  //   name: "Charlotte"
  // },
  // {
  //   lat: 35.5951,
  //   lng: -82.5515,
  //   name: "Asheville"
  // },
  // {
  //   lat: 39.9526,
  //   lng: -75.1652,
  //   name: "Philadelphia"
  // },
  // {
  //   lat: 42.3601,
  //   lng: -71.0589,
  //   name: "Boston"
  // },
  // {
  //   lat: 41.3083,
  //   lng: -72.9279,
  //   name: "New Haven"
  // }
]
export default places;