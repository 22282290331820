import "./App.css";

import React, { Component, useEffect } from "react";

import "leaflet/dist/leaflet.css";
// @ts-ignore
import Modal from "./Modal";
import places, { Place, citiesToAlbumIDs } from "./places";
import { Typography } from "@mui/material";
import AudioPlayer from "./AudioPlayer";
import { DataLayer } from "./dataLayer";
export const FLICKR_ID = "198036663@N02";
export const FLICKR_KEY = "621561ea1090c9d433683f7e6bd24a4b";

let mapLoaded = false;
function TravelMap() {
  const [showModal, setShowModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [flickrAlbum, setFlickrAlbum] = React.useState("");

  // @ts-ignore
  var ua = navigator.userAgent || navigator.vendor || window.opera;

  var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;

  const url = `https://api.flickr.com/services/rest/?method=flickr.photosets.getList&api_key=${FLICKR_KEY}&user_id=${FLICKR_ID}&format=json&nojsoncallback=1`;

  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      // handle the data here
      console.log(data.photosets.photoset); // array of public albums
    })
    .catch((error) => {
      // handle errors here
      console.error(error);
    });

  useEffect(() => {
    if (mapLoaded) {
      return;
    }
    function handleLabelClick(label: Place) {
      // @ts-ignore
      DataLayer.push({
        event: "clickedCity",
        city: label.name,
      });
      if (!label) {
        return;
      }
      setFlickrAlbum(
        // @ts-ignore
        citiesToAlbumIDs[label.name.toLowerCase().replace(" ", "")]
      );
      setModalTitle(`${label.emoji} ${label.name}`);
      setShowModal(true);
    }

    const globe = window
      // @ts-ignore
      .Globe()
      .globeImageUrl("//unpkg.com/three-globe/example/img/earth-night.jpg")
      .backgroundImageUrl("//unpkg.com/three-globe/example/img/night-sky.png")
      .labelsData(places)

      .labelText((d: Place) => "")
      .labelSize((d: Place) => 1)
      .onLabelClick(handleLabelClick)
      .labelDotRadius((d: Place) => 1)
      .labelColor(() => "darkorange")(document.getElementById("map"));

    globe.pointOfView({ altitude: 1.25, lat: 37.4467, lng: 25.3289 });

    if (isInstagram) {
      setTimeout(() => {
        // @ts-ignore
        if (globe.renderer().getCurrentViewport().height > 2000) {
          window.location.reload();
        }
      }, 2000);
    }
    mapLoaded = true;
  }, []);

  return (
    <>
      <AudioPlayer />
      <Modal
        show={showModal}
        handleClose={() => {
          var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor!.setAttribute("content", "#000000");
          setShowModal(false);
        }}
        title={modalTitle}
        flickrAlbum={flickrAlbum}
      />
    </>
  );
}

export default TravelMap;
