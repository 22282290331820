import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Swiper, SwiperSlide } from "swiper/react";

import { FLICKR_KEY, FLICKR_ID } from "./App";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ show, handleClose, title, body, flickrAlbum }: any) => {
  const isPortrait = window.innerHeight > window.innerWidth;

  const [currentImage, setCurrentImage] = useState(null);
  const [images, setImages] = useState([]);
  useEffect(() => {
    const url = `https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=${FLICKR_KEY}&photoset_id=${flickrAlbum}&user_id=${FLICKR_ID}&format=json&nojsoncallback=1`;
    if (flickrAlbum) {
      setImages([]);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const photos = data.photoset.photo;
          const photoUrls = photos.map((photo: any) => ({
            portraitUrl: `https://live.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_c.jpg`,
            landscapeUrl: `https://live.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_b.jpg`,
          }));
          setImages(photoUrls);
        })
        .catch((error) => console.error(error));
    }

    document.addEventListener("keydown.modal", function handleEscape(event) {
      console.log({ event });
      // @ts-ignore
      if (event.key === "Escape") {
        handleClose();
        document.removeEventListener("keydown.modal", handleEscape);
      }
    });
  }, [flickrAlbum]);
  const dialogOpen = images.length > 0 && show;
  useEffect(() => {
    if (dialogOpen) {
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor!.setAttribute("content", "#556a6f");
    }
  }, [dialogOpen]);
  const swiperRef = useRef(null);
  const pauseAutoplay = () => {
    // @ts-ignore
    swiperRef.current.swiper.autoplay.stop();
  };

  const resumeAutoplay = () => {
    // @ts-ignore
    swiperRef!.current!.swiper.autoplay.start();
  };

  return (
    <Dialog
      open={dialogOpen}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      sx={{
        background: "#aad3df",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          if (currentImage) {
            setCurrentImage(null);
            resumeAutoplay();
          } else {
            handleClose();
          }
        }}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          margin: 0,
          p: 0,
          zIndex: 3,
          color: (theme) => "#fff",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{ color: "white", fontFamily: "Lato", fontWeight: "bold" }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          mt: 4,
          width: isPortrait ? undefined : "600px",
          mx: isPortrait ? undefined : "auto",
        }}
      >
        {currentImage && (
          <img
            // @ts-ignore
            src={currentImage.portraitUrl}
            onClick={() => {
              if (currentImage) {
                setCurrentImage(null);
                resumeAutoplay();
              } else {
                handleClose();
              }
            }}
            alt="gallery"
            style={{
              width: isPortrait ? "100%" : "auto",
              height: "100%",
              position: "absolute",
              top: 0,
              zIndex: 2,
              left: 0,
              right: 0,
              bottom: 0,
              objectFit: "cover",
            }}
          />
        )}
        <Swiper
          ref={swiperRef}
          style={{
            // @ts-ignore
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#000",
            height: "100%",
          }}
          loop
          pagination={{
            type: "progressbar",
          }}
          lazy={true}
          autoplay={{
            delay: 2500,
          }}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          {images.map((image) => {
            return (
              // @ts-ignore
              <SwiperSlide key={image.portraitUrl}>
                <Grid container justifyContent="center" alignItems="center">
                  <img
                    loading="lazy"
                    onClick={() => {
                      if (isPortrait) {
                        pauseAutoplay();
                        setCurrentImage(image);
                      }
                    }}
                    alt="gallery"
                    style={{
                      width: isPortrait ? "100%" : "auto",
                      height: "100%",
                      objectFit: "contain",
                      overflow: "auto",
                      boxShadow:
                        "0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23)",
                    }}
                    // @ts-ignore
                    src={isPortrait ? image.portraitUrl : image.landscapeUrl}
                  />
                </Grid>
                <div className="swiper-lazy-preloader" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
