import { Pause, PlayArrow } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { DataLayer } from "./dataLayer";

function AudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);

  function togglePlay() {
    const audio = document.getElementById("myAudio") as HTMLAudioElement;

    // @ts-ignore
    DataLayer.push({
      event: "clickedAudio",
      play: audio.paused,
    });

    if (audio.paused) {
      if (audio.played.length === 0) {
        audio.currentTime = 60;
      }

      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  }

  return (
    <div
      id="audio-player"
      style={{
        position: "fixed",
        bottom: 8,
        zIndex: 100,
        right: 8,
        backgroundColor: "rgba(255,255,255,0.4)",
        borderRadius: "32px",
      }}
    >
      <audio id="myAudio">
        <source
          src="https://dl.dropbox.com/s/noaixxbyjrn3qah/07%20Around%20the%20World_small.mp3?dl=0"
          type="audio/mp3"
        />
      </audio>
      <IconButton size="small" onClick={togglePlay}>
        {isPlaying ? <Pause htmlColor="fff" /> : <PlayArrow htmlColor="fff" />}
      </IconButton>
    </div>
  );
}

export default AudioPlayer;
